import React from 'react';
import styled from 'styled-components'
import css from '@styled-system/css'
import { Link } from "gatsby"
import { Grid, GridItem, Typography, Box } from '@mintuz/marvel'
import { GhostButton } from '../components/GhostButton'
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { HeaderStyled, HeaderContentStyled } from '../components/HeaderStyled';
import { Logo } from '../components/Logo';
import { Pill } from '../components/Pill';

const GridWrap = styled.div`
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
`

const GridWrapSmall = styled(GridWrap)`
    max-width: 700px;
`

const WorkoutList = styled(Grid)`
    list-style-type: none;
`

const StyledLink = styled(Link)`
    text-decoration: none;

    ${css({
        color: "purple",
    })}

    &:hover {
        text-decoration: underline;
    }
`

const NavLink = props => {
    if (!props.test) {
        return <StyledLink to={props.url}>{props.text}</StyledLink>;
    } else {
        return <span>{props.text}</span>;
    }
};

const StyledPagination = styled.nav`
    display: flex;
    justify-content: space-between;
`

const Pagination = ({ first, last, previousUrl, nextUrl }) => {
    return (
        <StyledPagination>
            <div className="c-pagination__previous o-btn o-btn--large o-btn--light-gray">
                <NavLink test={first} url={previousUrl} text="Previous Page" />
            </div>
            <div className="c-pagination__next o-btn o-btn--large o-btn--light-gray">
                <NavLink test={last} url={nextUrl} text="Next Page" />
            </div>
        </StyledPagination>
    )
}

export default ({pageContext}) => {
    const { group: workouts, index, first, last } = pageContext;
    const previousUrl =
        index - 1 === 1 ? '/workouts' : `/workouts/${(index - 1).toString()}`;
    const nextUrl = `/workouts/${(index + 1).toString()}`;
    const pageCount = workouts.length

    return (
        <Layout>
            <SEO
                title="Free workout plans"
                description="Collection of carefully created workouts for all goals and levels of experience."
                keywords={[
                    `workout plan app`,
                    `workout database`,
                    `free workout plans`,
                    `full body workout`,
                    `best chest workout`,
                    `best body exercises`,
                    `women ab exercises`,
                    `women abs exercises`,
                    `ab workout`,
                    `abs workout`,
                    `workout plans for women`,
                    `workout plan app for women`,
                ]}
            />
            <HeaderStyled>
                <GridWrap>
                    <Grid>
                        <GridItem width={[1 / 1]}>
                            <Box pt={[[4]]} display="flex" justifyContent="space-between" alignItems="center">
                                <Link to="/"><Logo /></Link>
                                <GhostButton as={Link} to="/workouts" variant="secondary">Free Workouts</GhostButton>
                            </Box>
                        </GridItem>
                        <GridItem width={[1 / 1]}>
                            <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                                <HeaderContentStyled color="white">
                                    <Typography as="h1" variant="displayOne" mb={[3]}>Free workout plans</Typography>
                                    <Typography as="p" variant="displayThree">Collection of carefully created workouts for all goals and levels of experience.</Typography>
                                </HeaderContentStyled>
                            </Box>
                        </GridItem>
                    </Grid>
                </GridWrap>
            </HeaderStyled>
            <Box pt="100px" pb={[6]}>
                <GridWrapSmall>
                    <WorkoutList as="ul">
                        {
                            workouts.map(({node: workout}) => {
                                return (
                                    <GridItem width={[1 / 1]}>
                                        <Box mb={[6]}>
                                            <Pill>
                                                <StyledLink to={`/workouts${workout.slug}`}>
                                                    <Typography as="h2" mt={[0]} mb={[2]} variant="displayThree">
                                                        {workout.name}
                                                    </Typography>
                                                </StyledLink>
                                                {workout.tags.join(', ')}
                                            </Pill>
                                        </Box>
                                    </GridItem>
                                );
                            })
                        }
                    </WorkoutList>
                    {pageCount > 6 &&
                        <Pagination first={first} last={last} previousUrl={previousUrl} nextUrl={nextUrl} />
                    }
                </GridWrapSmall>
            </Box>
        </Layout>
    )
}
